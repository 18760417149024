import React from "react";
import { Row, Col } from "react-grid-system";
import styled from "styled-components";
import { formatPlaca } from "../../../shared/Format";

const Placa = styled.h1`
  background-color: #dedede;
  border: 1px solid #ccc;
  padding: 6px;
  display: inline-block;
  border-radius: 4px;
  font-size: 18px;
  margin: 0;
  margin-right: 16px;
  margin-bottom: 16px;
  text-align: center;
  @media (max-width: 460px) {
    margin-right: 8px;
    font-size: 14px;
  }
`;

const TipoVeiculo = styled.h1`
  font-size: 18px;
  display: inline-block;
  font-weight: 500;
  margin: 0;
  @media (max-width: 460px) {
    font-size: 18px;
  }
`;

// const CPF = styled.span`
//   margin: 0;
//   font-size: 20px;
//   display: inline-block;
//   font-weight: 500;

//   @media (max-width: 460px) {
//     font-size: 18px;
//   }
// `;

// const Proprietario = styled.span`
//   font-size: 20px;
//   display: inline-block;
//   font-weight: 500;

//   @media (max-width: 460px) {
//     font-size: 18px;
//   }
// `;

const Value = styled.span`
  font-weight: 700;
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const Title = styled.span`
  display: block;
  font-weight: 300;
  font-size: 14px;
  margin-top: 8px;

  @media (max-width: 600px) {
    margin-top: 8px;
  }

  @media (min-width: 600px) {
    color: grey;
    font-size: 11px;
    font-weight: 600;
    display: block;
  }
`;

function Veiculo({ veiculo }) {
  const {
    placa,
    tipoNome,
    // rntrc,
    renavam,
    // proprietarioCpfCnpj,
    // proprietarioNome,
    estadoLicenciamento,
    municipioLicenciamento,
    // chassi,
    eixos,
    // anoExercicio
  } = veiculo || {};
  return (
    <div style={{ paddingTop: '10px' }}>
      <Row>
        <Col xs={12}>
          <Placa>{formatPlaca(placa)} </Placa>
          <TipoVeiculo>{tipoNome}</TipoVeiculo>
        </Col>
        <Col xs={2} sm={2} lg={2}>
          <Title>Eixos</Title>
          <Value>{eixos}</Value>
        </Col>
        <Col xs={4} sm={4} lg={4}>
          <Title>Renavam</Title>
          <Value>{renavam}</Value>
        </Col>
        <Col xs={6} sm={6} lg={6}>
          <Title>Local Licenciamento</Title>
          <Value>
            {municipioLicenciamento} - {estadoLicenciamento}
          </Value>
        </Col>
      </Row>
    </div>
  );
}

export default Veiculo;
