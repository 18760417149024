import React, { useMemo } from "react";

import {
  Container,
  HeaderContainer,
  LogoContainer,
  LotContainer,
  CONumberValidityContainer,
  COImageNumberContainer,
  ValidityContainer,
  TenantLogo,
  CONumber,
  COInfoContainer,
  COInfo,
  COInfoContractCodeContainer,
  COInfoProductVolumeContainer,
  COInfoResponsibleCompanyContainer,
  ResponsibleCompanyContainer,
  ResponsibleCompanyInfo,
} from "./styles";

import Truckplaceholder from "../../../../assets/truckplaceholder.png";

const INTERNAL_LOADING_ORDER = 2;

const Lot = ({ data = {} }) => {
  let lotInfo = data || {};

  const isOC = useMemo(
    () => lotInfo.fluxoGeracaoOCAutomatica === INTERNAL_LOADING_ORDER,
    [lotInfo.fluxoGeracaoOCAutomatica]
  );

  return (
    <Container>
      <HeaderContainer>
        <div>
          <span>
            {isOC
              ? "Ordem de carregamento digital"
              : " Termo de solicitação de carregamento"}
          </span>
          <span>O.C Digital</span>
        </div>

        <LogoContainer>
          <div>{isOC && <img src="/logo-carguero.png" alt="Carguero" />}</div>
          <span>Solicitado em {lotInfo.requestDate}</span>
        </LogoContainer>
      </HeaderContainer>

      <LotContainer>
        <CONumberValidityContainer>
          <COImageNumberContainer>
            <TenantLogo>
              <img
                src={lotInfo.imageUrl || Truckplaceholder}
                alt="Tenant logo"
              />
            </TenantLogo>

            <CONumber>
              <span className="ocNumber">Nº O.C</span>
              <span>{lotInfo.loadingOrderNumber}</span>
            </CONumber>
          </COImageNumberContainer>

          <ValidityContainer>
            <span>Validade O.C</span>
            <span>{lotInfo.validity}</span>
          </ValidityContainer>
        </CONumberValidityContainer>

        <COInfoResponsibleCompanyContainer>
          <COInfoContainer>
            <div>
              <span>Dados da O.C</span>
            </div>

            <COInfo>
              <COInfoContractCodeContainer>
                <div>
                  <span>Nº contrato</span>
                  <span>{lotInfo.contractNumber}</span>
                </div>

                <div>
                  <span>Código localizador</span>
                  <span>{lotInfo.locatorCode}</span>
                </div>
              </COInfoContractCodeContainer>

              <COInfoProductVolumeContainer>
                <div>
                  <span>Produto</span>
                  <span>{lotInfo.product}</span>
                </div>

                <div>
                  <span>Volume</span>
                  <span>{`${lotInfo.volume} ${lotInfo.unitOfMeasurement}`}</span>
                </div>
              </COInfoProductVolumeContainer>
            </COInfo>
          </COInfoContainer>

          <ResponsibleCompanyContainer>
            <div>
              <span>Empresa responsável</span>
            </div>

            <ResponsibleCompanyInfo>
              <div>
                <span>Nome</span>
                <span>{lotInfo.responsibleCompany}</span>
              </div>

              {isOC && (
                <div>
                  <span>Filial</span>
                  <span>{lotInfo.subsidiary}</span>
                </div>
              )}
            </ResponsibleCompanyInfo>
          </ResponsibleCompanyContainer>
        </COInfoResponsibleCompanyContainer>
      </LotContainer>
    </Container>
  );
};

export default Lot;
