import React from "react";
import styles from "./Search.module.scss";
import { SearchButton } from "./StyledComponents";

class Search extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputCPF = React.createRef();
    this.inputCodigo = React.createRef();
  }

  state = {
    codigo: "",
    cpf: ""
  };

  onClean() {
    this.setState({ cpf: "", codigo: "" });
    this.inputCPF.current.focus();
    this.props.onClickClean();
  }

  carregaCPF(dado) {
    this.setState({ cpf: dado });
    this.props.onChangeCpfViagem(dado);
  }

  carregaCodigo(dado) {
    this.setState({ codigo: dado });
    this.props.onChangeCodigoViagem(dado);
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.wrapperSearch}>
          <div className={styles.blocoInput}>
            <p className={styles.blocoLabel}>CPF do Motorista</p>
          </div>

          <div className={styles.blocoInput}>
            <input
              ref={this.inputCPF}
              autoFocus
              placeholder="Digite somente números"
              maxLength={11}
              className={styles.SearchInput}
              value={this.state.cpf}
              onChange={e => this.carregaCPF(e.target.value)}
            />
          </div>

          <div className={styles.blocoInput}>
            <p className={styles.blocoLabel}>Código Localizador</p>
          </div>

          <div className={styles.blocoInput}>
            <input
              ref={this.inputCodigo}
              maxLength={12}
              className={styles.SearchInput}
              value={this.state.codigo}
              onChange={e => this.carregaCodigo(e.target.value)}
            />
          </div>

          <SearchButton
            disabled={this.props.loading}
            onClick={this.props.onClickSearch}
          >
            Buscar Viagem
          </SearchButton>

          {this.state.cpf || this.state.codigo ? (
            <button
              className={styles.cleanButton}
              onClick={() => this.onClean()}
            >
              Nova Busca
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Search;
