import styled from "styled-components";

const Logo = styled.div`
  width: 100%;
  max-width: 350px;
  margin: 30px auto;
  text-align: center;
  display: block;

  img {
    max-width: 100%;
  }
`;

const Title = styled.span`
  font-size: 30px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  display: block;
  @media all and (max-width: 480px) {
    font-size: 24px;
  }
`;

const SearchButton = styled.button`
  background-color: #1fb13c;
  color: white;
  height: 45px;
  width: 365px;
  border-radius: 4px;
  margin-top: 15px;
  border: 0px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;

  :focus {
    outline: none !important;
  }

  :disabled {
    background-color: #d5d5d5;
    &:hover {
      background-color: #d5d5d5;
    }
  }

  :hover {
    background-color: rgb(23, 150, 35);
  }

  @media all and (max-width: 480px) {
    & {
      float: none;
      width: 300px;
      border-radius: 4px;
      margin-top: 20px;
    }
  }
`;

export { Title, SearchButton, Logo };
