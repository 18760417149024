import React from 'react'
import styles from './StatusViagem.module.scss';
import { Row } from 'react-grid-system';
import { setStatusViagemIcon, setStatusViagemColor, setStatusViagemName } from '../../../shared/StatusViagem';

class StatusViagem extends React.PureComponent {
    render() {
        const { data } = this.props;
        return (
            <div>
                <Row justify='center'>
                    <div className={styles.statusTitleArea}>
                        <span className={styles.statusTitle}>Status da Viagem</span>
                    </div>
                </Row>
                <Row justify='center'>
                    <div className={styles.statusViagem} style={{ backgroundColor: setStatusViagemColor(data.statusViagem.codigo) }}>
                        <i className={setStatusViagemIcon(data.statusViagem.codigo) + ' ' + styles.iconStatusViagem} aria-hidden="true" />
                        <span style={{ marginLeft: '10px' }}>
                            {setStatusViagemName(data.statusViagem.codigo)}
                        </span>
                    </div>
                </Row>
            </div>
        )
    }
}

export default StatusViagem