import React from "react";
import styles from "./ErrorPanel.module.scss";

class ErrorPanel extends React.PureComponent {
  render() {
    const { message } = this.props;

    return (
      <div className={styles.errorModal}>
        <div className={styles.errorIcon}>
          <i className="fa fa-times" />
        </div>
        <div className={styles.errorArea}>
          <span className={styles.titleError}>
            CPF / Código não encontrados.
          </span>
          <span className={styles.textError}>{message}</span>
        </div>
      </div>
    );
  }
}

export default ErrorPanel;
