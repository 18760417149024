import React from "react";
import styles from "./Agendamento.module.scss";
import { Row } from "react-grid-system";
import moment from 'moment'


class Agendamento extends React.PureComponent {
  render() {
    const { data } = this.props;
    let dataAgendamento = data.dataAgendamento;

    return (
      <div>
        <Row className={styles.agendamentoArea} justify='center'>
          <div>
            <span className={styles.agendamentoTitle}>
              Data de Agendamento
            </span>
            <span className={styles.agendamentoData}>
              {moment(dataAgendamento).format('DD/MM/YYYY')}
            </span>
          </div>
        </Row>
      </div>
    );
  }
}

export default Agendamento;
