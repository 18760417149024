import React, { useEffect, useState } from 'react';

import {
  Container,
  VehicleContainer,
  TruckTipeOwnerContainer,
  PlateContainer,
  DocumentContainer
} from './styles';

const Vehicle = ({ data = [] }) => {
  const [vehicleInfo, setVehicleInfo] = useState([]);

  useEffect(() => {
    setVehicleInfo(data);
  }, [data]);

  return (
    <Container>
      <div>
        <span>Dados do veículo</span>
      </div>
      {
        vehicleInfo && vehicleInfo.map((vehicle, key) => (
          <VehicleContainer
            key={key}
            className={key === 1 ? 'pageBreak' : null}
          >
            <TruckTipeOwnerContainer>
              <div>
                <span>
                  {key === 0 ? 'Tipo de caminhão' : `Carroceria ${key}`}
                </span>
                <span>{vehicle.truckSubType}</span>
              </div>

              <div>
                <span>Cidade</span>
                <span>{vehicle.city}</span>
              </div>

              <div>
                <span>Proprietário do veículo</span>
                <span>{vehicle.ownerName}</span>
              </div>
            </TruckTipeOwnerContainer>

            <PlateContainer>
              <div>
                <span>Placa</span>
                <span>{vehicle.plate}</span>
              </div>

              <div>
                <span>Estado</span>
                <span>{vehicle.licenseState}</span>
              </div>
            </PlateContainer>

            <DocumentContainer>
              <div>
                <span>CPF/CNPJ</span>
                <span>{vehicle.cpfCnpj}</span>
              </div>

              <div>
                <span>ANTT</span>
                <span>{vehicle.antt}</span>
              </div>
            </DocumentContainer>
          </VehicleContainer>
        ))
      }
    </Container>
  );
}

export default Vehicle;
