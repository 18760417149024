import CPF from "cpf";

function formatLine(text, size) {
  if (!text) return text;

  if (text.length > size) return text.slice(0, size - 3).trim() + "...";

  return text;
}

function formatCpf(n) {
  if (CPF.isValid(n, true)) {
    return CPF.format(n);
  }
  return n && n.toString;
}

function validarCpf(strCPF) {
  let soma = 0;
  let resto;

  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++) {
    soma = soma + Number(strCPF.substring(i - 1, i)) * (11 - i);
  }

  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;

  if (resto !== Number(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma = soma + Number(strCPF.substring(i - 1, i)) * (12 - i);
  }

  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  
  if (resto !== Number(strCPF.substring(10, 11))) return false;
  return true;
}

function formatNumber(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function formatPlaca(x) {
  return x
    .toString()
    .replace(/(\D{3})(\d{4})/g, "$1-$2")
    .toUpperCase();
}

export { formatLine, formatCpf, formatNumber, validarCpf, formatPlaca };
