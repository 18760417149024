import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import axios from "axios";

import Search from "../../components/Search";
import { Logo } from "../../components/StyledComponents";
import ErrorPanel from "../../components/ErrorPanel";
import InfoPanel from "../../components/InfoPanel";
import ResultPanel from "../../components/ResultPanel";
import { validarCpf } from "../../shared/Format";
import Loading from "../../shared/Loading";
import { GA_KEY } from '../../infra/config';

import './styles.scss';

const Home = () => {
  const [travelData, setTravelData] = useState({});
  const [travelCode, setTravelCode] = useState('');
  const [travelCpf, setTravelCpf] = useState('');
  const [message, setMessage] = useState('');
  const [info, setInfo] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (GA_KEY) {
      ReactGA.initialize(GA_KEY);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  const onChangeCodigoViagem = (value) => {
    setTravelCode(value);
  };

  const onChangeCpfViagem = (value) => {
    setTravelCpf(value);
  };

  const isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  const onClickClean = () => {
    setTravelCode('');
    setTravelCpf('');
    setTravelData({});
    setMessage('');
    setInfo(false);
    setLoading(false);
  }

  const getUser = async () => {
    setInfo(false);

    if (!travelCode || !travelCpf) {
      setTravelData(null);
      setMessage('Favor informar o CPF e Código desejados.');
    } else {
      let searchCode = travelCode.replace(/\s+/g, "");

      if (!validarCpf(travelCpf)) {
        setTravelData(null);
        setMessage('CPF informado é inválido!');
      } else {
        setLoading(true);
        setTravelData({});
        setMessage('');

        if (travelCode && travelCpf) {
          try {
            if (travelCode.length <= 6) {
              const response = await axios.get(
                "/viagens/portaria/" + searchCode + "?cpf=" + travelCpf,
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*"
                  }
                }
              );

              if (response.data.success) {
                if (response.data.result.expirada) {
                  setInfo(true);
                  setLoading(false);
                } else {
                  setTravelData(response.data);
                  setLoading(false);
                }
              } else {
                setTravelData(null);
                setLoading(false);
                setMessage('CPF / Código informado inválido. Confira o código no aplicativo do motorista e tente novamente');
              }
            } else {
              setTravelData(null);
              setLoading(false);
              setMessage('CPF / Código informado inválido. Confira o código no aplicativo do motorista e tente novamente');
            }

          } catch (error) {
            setInfo(false);
            setTravelData(null);
            setLoading(false);
            setMessage('A busca não encontrou resultado. Confira o CPF / Código no aplicativo do motorista e tente novamente');
          }
        } else {
          setTravelCode('');
          setTravelData({});
          setLoading(false);
          setInfo(false);
        }
      }
    }
  }

  return (
    <div className="App">
      <Logo>
        <img src="/logo.png" alt="Carguero Portaria" />
      </Logo>

      <Search
        onClickSearch={() => getUser()}
        onChangeCodigoViagem={value => onChangeCodigoViagem(value)}
        onChangeCpfViagem={value => onChangeCpfViagem(value)}
        onClickClean={() => onClickClean()}
        loading={loading}
      />

      {loading ? (
        <div>
          <Loading loading={loading} />
        </div>
      ) : null}

      {info ? <InfoPanel /> : null}

      {!travelData ? <ErrorPanel message={message} /> : null}

      {isEmpty(travelData) ? null : (
        <div>
          <ResultPanel dadosViagem={travelData.result} />
        </div>
      )}
    </div>
  );
}

export default Home;