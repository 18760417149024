import styled from 'styled-components';

export const Container = styled.section`
  margin-top: 10px;
  font-size: 10px;
  line-height: 1.4;

  > div:first-child > span:first-child {
    color: #53772C;
    font-weight: 600;
  }

  @media (max-width: 414px) {
    font-size: 12px;
  }
`;

export const VehicleContainer = styled.div`
  display: grid;
  grid-template-columns: 53% 18% 23%;
  gap: 1.2rem;

  margin-top: 7px;
  padding: 5px 12px;
  background: #6CA2391F;
  border-radius: 5px;

  @media (max-width: 414px) {
    grid-template-columns: 51% 44%;
    gap: 0;

    & > div:nth-child(2) {
      margin-left: 1rem;
    }

    padding: 12px;
    margin-top: 10px;
  }
`;

export const TruckTipeOwnerContainer = styled.div`
  div + div {
    margin-top: 2px;
  }

  div {
    display: flex;
    flex-direction: column;

    span {
      font-weight: 600;
    }
  }

  & > div > span:first-child {
    font-weight: 300;
  }

  @media (max-width: 414px) {
    div + div {
      margin-top: 10px;
    }
  }
`;

export const PlateContainer = styled.div`
  div + div {
    margin-top: 2px;
  }

  div {
    display: flex;
    flex-direction: column;

    span {
      font-weight: 600;
    }
  }

  & > div > span:first-child {
    font-weight: 300;
  }

  @media (max-width: 414px) {
    div + div {
      margin-top: 10px;
    }
  }
`;

export const DocumentContainer = styled.div`
  div + div {
    margin-top: 2px;
  }

  div {
    display: flex;
    flex-direction: column;

    span {
      font-weight: 600;
    }
  }

  & > div > span:first-child {
    font-weight: 300;
  }

  @media (max-width: 414px) {
    display: grid;
    grid-template-columns: 100% 86%;
    gap: 1rem;

    margin-left: 0;
    margin-top: 10px;

    & > div:nth-child(2) {
      margin-top: 0;
    }
  }
`;
