import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Row, Hidden } from "react-grid-system";
import { FLOW_TYPE } from "../../../shared/Enum";
import styles from "./OrdemCarregamento.module.scss";

const OrdemCarregamento = ({ data, travelId }) => {
  const isUrlFlow = useMemo(
    () =>
      data.fluxoGeracaoOCAutomatica !== FLOW_TYPE.INTERNAL_LOADING_ORDER &&
      data.fluxoGeracaoOCAutomatica !== FLOW_TYPE.INTERNAL_LOADING_TERM,
    [data.fluxoGeracaoOCAutomatica]
  );

  let ordemCarregamentoEmbarcadora =
    data.ordemCarregamentoEmbarcadora && data.ordemCarregamentoEmbarcadora.url;

  let ordemCarregamentoTransportadora =
    (data.ordemCarregamentoTransportadora &&
      data.ordemCarregamentoTransportadora.url) ||
    !isUrlFlow;

  return (
    <div>
      <Row className={styles.ordemCarregamentoArea} justify="between">
        <div>
          <span className={styles.ordemCarregamentoTitle}>
            Ordem de Carregamento Embarcadora
          </span>
          <span
            className={
              data.ordemCarregamentoEmbarcadora &&
              data.ordemCarregamentoEmbarcadora.numero
                ? styles.ordemCarregamento
                : styles.ordemCarregamentoDisabled
            }
          >
            {data.ordemCarregamentoEmbarcadora &&
            data.ordemCarregamentoEmbarcadora.numero
              ? data.ordemCarregamentoEmbarcadora.numero
              : "- - - - -"}
          </span>
        </div>
        <div
          className={
            ordemCarregamentoEmbarcadora
              ? styles.ordemCarregamentoDownloadArea
              : styles.ordemCarregamentoDownloadAreaDisabled
          }
        >
          <div className={styles.ordemCarregamentoDownload}>
            <a
              onClick={(event) => {
                if (!ordemCarregamentoEmbarcadora) {
                  event.preventDefault();
                }
              }}
              href={
                ordemCarregamentoEmbarcadora
                  ? ordemCarregamentoEmbarcadora
                  : "#"
              }
              rel="noopener noreferrer"
              target="_blank"
              className={
                ordemCarregamentoEmbarcadora
                  ? styles.ordemCarregamentoDownload
                  : styles.ordemCarregamentoDownloadDisabled
              }
            >
              <i
                className={"fa fa-download " + styles.ordemCarregamentoIcon}
                aria-hidden="true"
              />
              <Hidden xs md sm className={styles.ordemCarregamentoText}>
                Baixar Ordem
              </Hidden>
            </a>
          </div>
        </div>
      </Row>
      <Row className={styles.ordemCarregamentoArea} justify="between">
        <div>
          <span className={styles.ordemCarregamentoTitle}>
            Ordem de Carregamento Transportadora
          </span>
          <span
            className={
              data.ordemCarregamentoTransportadora &&
              data.ordemCarregamentoTransportadora.numero
                ? styles.ordemCarregamento
                : styles.ordemCarregamentoDisabled
            }
          >
            {data.ordemCarregamentoTransportadora &&
            data.ordemCarregamentoTransportadora.numero
              ? data.ordemCarregamentoTransportadora.numero
              : "- - - - -"}
          </span>
        </div>
        <div
          className={
            ordemCarregamentoTransportadora
              ? styles.ordemCarregamentoDownloadArea
              : styles.ordemCarregamentoDownloadAreaDisabled
          }
        >
          <div className={styles.ordemCarregamentoDownload}>
            {isUrlFlow ? (
              <a
                onClick={(event) => {
                  if (
                    !data.ordemCarregamentoTransportadora ||
                    !data.ordemCarregamentoTransportadora.url
                  ) {
                    event.preventDefault();
                  }
                }}
                href={
                  data.ordemCarregamentoTransportadora &&
                  data.ordemCarregamentoTransportadora.url
                    ? data.ordemCarregamentoTransportadora.url
                    : "#"
                }
                rel="noopener noreferrer"
                target="_blank"
                className={
                  ordemCarregamentoTransportadora
                    ? styles.ordemCarregamentoDownload
                    : styles.ordemCarregamentoDownloadDisabled
                }
              >
                <i
                  className={"fa fa-download " + styles.ordemCarregamentoIcon}
                  aria-hidden="true"
                />
                <Hidden xs md sm className={styles.ordemCarregamentoText}>
                  Baixar Ordem
                </Hidden>
              </a>
            ) : (
              <Link
                to={`${travelId}/loading-order`}
                rel="noopener noreferrer"
                target="_blank"
                className={
                  ordemCarregamentoTransportadora
                    ? styles.ordemCarregamentoDownload
                    : styles.ordemCarregamentoDownloadDisabled
                }
              >
                <i
                  className={"fa fa-download " + styles.ordemCarregamentoIcon}
                  aria-hidden="true"
                />
                <Hidden xs md sm className={styles.ordemCarregamentoText}>
                  Baixar Ordem
                </Hidden>
              </Link>
            )}
          </div>
        </div>
      </Row>
    </div>
  );
};

export default OrdemCarregamento;
