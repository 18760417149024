import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  margin-top: 5px;

  @media (max-width: 414px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ItineraryContainer = styled.div`
  font-size: 10px;

  > div:first-child > span:first-child {
    color: #53772C;
    font-weight: 600;
  }

  @media (max-width: 414px) {
    margin-top: 5px;

    font-size: 12px;
  }
`;

export const ItineraryTimeLineContainer = styled.div`
  display: grid;
  grid-template-columns: 53% 44%;
  gap: 1.2rem;

  font-size: 10px;
  line-height: 1.4;

  div > span:first-child {
    font-weight: 300;
    margin-bottom: 5px;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      background: #6CA239;
      margin-top: 3px;
      margin-left: -15px;
      border-radius: 50%;
    }
  }

  div {
    margin-top: 0;
    margin-left: 15px;

    span {
      display: flex;
      flex-direction: column;
      font-weight: 600;
    }
  }

  @media (max-width: 414px) {
    display: flex;
    flex-direction: column;
    gap: 0;

    font-size: 12px;

    & > div:first-child {
      margin-top: 0;
    }

    div {
      margin-top: 10px;
      margin-left: 16px;
    }

    div > span:first-child {
      font-weight: 300;
      margin-bottom: 5px;
    }

    &::before {
      content: '';
      height: ${props => (props.timelineHeight + 13)}px;
      border: 1px dashed #6CA239;
      position: absolute;
      margin-top: 7px;
      margin-left: 5px;
    }
  }
`;

export const DriverContainer = styled.div`
  font-size: 10px;
  line-height: 1.4;

  margin-top: 5px;

  border-top: 2px solid #6CA239;
  clear: both;

  & > div:first-child {
    margin-top: 0;
  }

  & > div {
    margin-top: 2px;

    span {
      display: flex;
      flex-direction: column;
      font-weight: 600;
    }
  }

  & > div:first-child > span:first-child {
    color: #53772C;
    font-weight: 600;

    margin-top: 5px;
  }

  div > span:first-child {
    font-weight: 300;
  }

  @media (max-width: 414px) {
    font-size: 12px;

    margin-top: 10px;

    & > div:first-child {
      margin-top: 10px;
    }
  }
`;

export const DriverInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 53% 17% 24%;
  gap: 1.2rem;

  @media (max-width: 414px) {
    grid-template-columns: 51% 49%;
    gap: 0;

    & > div:nth-child(2) {
      margin-top: 0;
      margin-left: 1rem;
    }

    & > div:last-child {
      margin-top: 10px;
    }
  }
`;


