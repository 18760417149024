import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';


const Loader = styled(BeatLoader)`
    display: block;
    margin: 0 auto;
    border-color: red;
    padding-top: 40px;
`;

class Loading extends React.PureComponent{
    
    render() {
        return (
          <div className='sweet-loading'>
            <Loader
              sizeUnit={"px"}
              size={16}
              color={'#1FB13C'}
              loading={this.props.loading}
            />
          </div> 
        )
      }
}



export default Loading