import styled from 'styled-components';

export const Container = styled.div`
  width: 555px;
  height: 100%;
  padding: 20px;
  margin: 40px auto;
  border: 1px #D3D3D3 solid;
  border-radius: 3px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  font-family: Roboto, sans-serif;
  line-height: 1.2em;

  overflow: hidden;

  @media print {
    html, body {
      padding: 0;
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @page {
    size: auto;
    margin: 20mm 0 0 0;
  }

  @media (max-width: 414px) {
    width: 414px;
  }
`;

export const Divider = styled.div`
  margin-top: 5px;
  border-bottom: 2px solid #6CA239;
  clear: both;

  @media (max-width: 414px) {
    margin-top: 10px;
  }
`;
