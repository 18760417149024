import React from "react";
import styles from "./ResultPanel.module.scss";
import { Container } from "react-grid-system";
import DadosTransportadora from "./components/DadosTransportadora";
import StatusViagem from "./components/StatusViagem";
import CodigoViagem from "./components/CodigoViagem";
import OrdemCarregamento from "./components/OrdemCarregamento";
import DestinoViagem from "./components/DestinoViagem";
import Veiculo from "./components/Veiculo";
import Agendamento from "./components/Agendamento";

class ResultPanel extends React.PureComponent {
  render() {
    const { dadosViagem } = this.props;

    const { informacaoLogistica } = dadosViagem || {};
    const { motorista } = informacaoLogistica || {};
    const { veiculos } = motorista || { veiculos: [] };

    return (
      <div className={styles.resultArea}>
        <Container className={styles.rowDivider}>
          <DadosTransportadora data={dadosViagem} />
        </Container>
        <Container className={styles.rowDivider}>
          <StatusViagem data={dadosViagem && dadosViagem.viagem} />
        </Container>
        <Container className={styles.rowDivider}>
          <CodigoViagem data={dadosViagem} />
        </Container>
        <Container className={styles.rowDivider}>
          {
            (dadosViagem.viagem.statusViagem.codigo === 16)
              ? <Agendamento data={dadosViagem.viagem} />
              : <OrdemCarregamento 
                  data={dadosViagem.informacaoLogistica} 
                  travelId={dadosViagem.viagem.viagemId} 
                />
          }
        </Container>
        <Container className={styles.rowDivider} style={{ paddingTop: "10px" }}>
          <DestinoViagem data={dadosViagem.informacaoLogistica} />
        </Container>
        <Container>
          {veiculos.map((veiculo, index) =>
            index !== veiculos.length - 1 ? (
              <div className={styles.rowDividerVeiculo} key={index}>
                <Veiculo veiculo={veiculo} />
              </div>
            ) : (
                <div className={styles.rowDivider} key={index}>
                  <Veiculo veiculo={veiculo} />
                </div>
              )
          )}
        </Container>
      </div>
    );
  }
}

export default ResultPanel;
