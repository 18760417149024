import React from "react";
import styles from "./InfoPanel.module.scss";

class InfoPanel extends React.PureComponent {
  render() {
    return (
      <div className={styles.infoModal}>
        <div className={styles.infoIcon}>
          <i className="fa fa-info" />
        </div>
        <div className={styles.infoArea}>
          <span className={styles.titleInfo}> Código localizador expirado </span>
          <span className={styles.textInfo}>
            A viagem correspondente ao código localizador não está mais
            disponível.
          </span>
        </div>
      </div>
    );
  }
}

export default InfoPanel;
