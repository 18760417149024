export const formatNumber = (valor, places = 0) => {
	if (typeof valor === 'undefined' || valor === null) return '';

	valor = +valor;

	if (isNaN(valor)) return '--';

	let isNegative = valor < 0;

  valor = Math.abs(valor);

  let milhar = '';
	let valorFinal = '';
	let inteiro = valor.toFixed(places);
	let decimal = '';

	if (places) {
		let pieces = inteiro.split('.');
		inteiro = pieces[0];
		decimal = pieces[1];
	}

	for (var i = inteiro.length - 1; i >= 0; i--) {
		milhar = inteiro[i] + milhar;

		if (milhar.length === 3) {
			if (i) milhar = '.' + milhar;
			valorFinal = milhar + valorFinal;
			milhar = '';
		}
	}

	valorFinal =
    (isNegative ? '-' : '') + milhar + valorFinal + (places ? ',' + decimal : '');

	return valorFinal;
};

export const formatCpfCnpj = (cpfCnpj, placeholder = '-') => {
  if (typeof cpfCnpj === 'undefined' || cpfCnpj === null) return placeholder;

  let cpfCnpjFormatted = '';

  if (cpfCnpj.length <= 11) {
    cpfCnpjFormatted = cpfMask(cpfCnpj);
  } else {
    cpfCnpjFormatted = cnpjMask(cpfCnpj);
  }

  function cpfMask(valor) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"$1.$2.$3-$4");
  }

  function cnpjMask(valor) {
    return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5");
  }

  return cpfCnpjFormatted;
}

export const formatPhoneNumber = (phoneNumber, placeholder = '-') => {
  if (typeof phoneNumber === 'undefined' || phoneNumber === null)
    return placeholder;

  let phoneNumberWithoutMask = phoneNumber.replace(/[^0-9]+/g,'');

  let phoneNumberFormatted = '';

  if (phoneNumberWithoutMask && phoneNumberWithoutMask.length === 10){
    phoneNumberFormatted = phoneNumberWithoutMask
      .replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  }

  if(phoneNumberWithoutMask && phoneNumberWithoutMask.length === 11){
    phoneNumberFormatted = phoneNumberWithoutMask
      .replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  }

  return phoneNumberFormatted;
}

export const formaZipCode = (zipCode) => {
  if (typeof zipCode === 'undefined' || zipCode === null) return '';

  let zipCodeWithoutMask = zipCode.replace(/[^0-9]+/g,'');

  let zipCodeFormatted = '';

  if(zipCodeWithoutMask && zipCodeWithoutMask.length === 8) {
    zipCodeFormatted = zipCodeWithoutMask.replace(/(\d{5})(\d{3})/, "$1-$2");
  }

  return zipCodeFormatted;
}
