import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import moment from 'moment';

import Lot from './components/Lot';
import Itinerary from './components/Itinerary';
import Vehicle from './components/Vehicle';

import {
  formatNumber,
  formatCpfCnpj,
  formatPhoneNumber,
  formaZipCode,
} from './utils/format';

import { Container, Divider } from './styles';

const LoadingOrder = (props) => {
  const [lotInfo, setLotInfo] = useState({});
  const [itineraryInfo, setItineraryInfo] = useState({});
  const [vehicleInfo, setVehicleInfo] = useState([]);

  const { match } = props;
  const travelId = match.params.id;

  useEffect(() => {
    editHtmlElements();

    async function loadingOrder() {
      const { data } = await Axios.get('/loadingOrder/' + travelId, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      });

      const { success, result } = data;

      if(success){
        setLotInfo({
          loadingOrderNumber: result.loadingOrderNumber,
          locatorCode: result.locatorCode,
          contractNumber: result.contractNumber,
          responsibleCompany: result.responsibleCompany,
          product: result.product,
          volume: formatNumber(result.volume),
          unitOfMeasurement: result.unitOfMeasurement,
          imageUrl: result.imageUrl,
          subsidiary: result.subsidiary,
          validity: moment(result.validity).format('DD/MM/YYYY'),
          requestDate: moment(result.requestDate).format('DD/MM/YYYY [às] HH:mm'),
        });

        setItineraryInfo({
          collectionLocationName:
            result.collectionLocationName,
          collectionLocationPublicPlace: result.collectionLocationPublicPlace,
          collectionLocationNumber: result.collectionLocationNumber,
          collectionLocationNeighborhood: result.collectionLocationNeighborhood,
          collectionLocationCity: result.collectionLocationCity,
          collectionLocationState: result.collectionLocationState,
          collectionLocationZipCode: formaZipCode(result.collectionLocationZipCode),
          dischargeLocationName:
            result.dischargeLocationName,
          dischargeLocationPublicPlace: result.dischargeLocationPublicPlace,
          dischargeLocationNumber: result.dischargeLocationNumber,
          dischargeLocationNeighborhood: result.dischargeLocationNeighborhood,
          dischargeLocationCity: result.dischargeLocationCity,
          dischargeLocationState: result.dischargeLocationState,
          dischargeLocationZipCode: formaZipCode(result.dischargeLocationZipCode),
          driverName: result.driverName,
          driverCpf: formatCpfCnpj(result.driverCpf),
          driverCelPhone: formatPhoneNumber(result.driverCelPhone),
        });

        const vehicleInfoFormatted = result.truckBodies.map(truckBody => {
          return {
            ...truckBody,
            cpfCnpj: formatCpfCnpj(truckBody.cpfCnpj),
          }
        })

        setVehicleInfo(vehicleInfoFormatted);
      }
    }
    
    loadingOrder();
  }, []);

  const editHtmlElements = useCallback(() => {
    const htmlElement = document.documentElement;
    const bodyElement = document.body;

    htmlElement.style.paddingTop = '0';
    htmlElement.style.background = '#ffffff'
    bodyElement.style.paddingTop = '0';
    bodyElement.style.background = '#ffffff'
  }, []);

  return (
    <Container>
      <Lot data={lotInfo} />
      <Divider />
      <Itinerary data={itineraryInfo} />
      <Divider />
      <Vehicle data={vehicleInfo} />
    </Container>
  );
}

export default LoadingOrder;
