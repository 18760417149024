import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Home from './Pages/Home';
import LoadingOrder from './Pages/LoadingOrder';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route component={Home} path="/" exact />
      <Route component={LoadingOrder} path="/:id/loading-order" exact />
    </Switch>
  </BrowserRouter>
)

export default App;
