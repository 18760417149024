function setStatusViagemName(id) {
  switch (id) {
    case 1:
      return "Aguardando carregamento";

    case 2:
      return "Aguardando liberação do embarcador";

    case 3:
      return "Carregado";

    case 4:
      return "Descarregado";

    case 5:
      return "Cancelado";

    case 6:
      return "Rejeitada pelo embarcador";

    case 7:
      return "Emitindo NF trânsito";

    case 8:
      return "Liberando carregamento";

    case 9:
      return "Carregamento rejeitado";

    case 10:
      return "Aguardando solicitação nota fiscal de trânsito";

    case 11:
      return "Em cancelamento";

    case 12:
      return "Aguardando carregamento interno";

    case 13:
      return "Em cancelamento pelo administrador";

    case 14:
      return "Cancelado pelo administrador";

    // 15 Aguardando Documentos Transito

    case 16:
      return "Em agendamento";

    case 17:
      return "Aguardando Liberação do Transportador";

    case 19:
      return "Aguardando Análise de Risco";

    case 20:
      return "Carregamento rejeitado pelo transportador";

    case 21:
      return "Carregamento recusado pela Gerenciadora de Risco";

    default:
      return "Aguardando carregamento";
  }
}

function setStatusViagemColor(status) {
  switch (status) {
    case 1:
      return "#4a90e2";

    case 2:
      return "#4a90e2";

    case 3:
      return "#0cb334";

    case 4:
      return "#0cb334";

    case 5:
      return "#d0021b";

    case 6:
      return "#d0021b";

    case 7:
      return "#4a90e2";

    case 8:
      return "#4a90e2";

    case 9:
      return "#d0021b";

    case 10:
      return "#4a90e2";

    case 11:
      return "#d0021b";

    case 12:
      return "#4a90e2";

    case 13:
      return "#d0021b";

    case 14:
      return "#d0021b";

    // 15 Aguardando Documentos Transito

    case 16:
      return "#4a90e2";

    default:
      return "#4a90e2";
  }
}
function setStatusViagemIcon(status) {
  switch (status) {
    case 1:
      return "ci-aguardando-carregamento";

    case 2:
      return "ci-aguardando-liberacao-embarcador";

    case 3:
      return "ci-carregado";

    case 4:
      return "ci-descarregado";

    case 5:
      return "ci-cancelado";

    case 6:
      return "ci-rejeitada";

    case 7:
      return "ci-emitindo-nf-transito";

    case 8:
      return "ci-liberando-carregamento";

    case 9:
      return "ci-carregamento-rejeitado";

    case 10:
      return "ci-aguardando-carregamento";

    case 11:
      return "ci-em-cancelamento";

    case 12:
      return "ci-aguardando-carregamento";

    case 13:
      return "ci-aguardando-resposta-cancelamento";

    case 14:
      return "ci-cancelado-administrador";

    case 16:
      return "ci-aguardando-liberacao-embarcador";

    default:
      return "ci-aguardando-carregamento";
  }
}

export { setStatusViagemIcon, setStatusViagemColor, setStatusViagemName };
