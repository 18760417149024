import React from 'react'
import styles from './DestinoViagem.module.scss';
import { Row, Col, Hidden } from 'react-grid-system';
import { formatCpf } from '../../../shared/Format'


class DestinoViagem extends React.PureComponent {

    render() {

        const { data } = this.props;
        const localColeta = data.rotaFrete && data.rotaFrete.localColeta;
        const localDescarga = data.rotaFrete && data.rotaFrete.localDescarga;

        return (
            <div>
                <Hidden className={styles.destinoViagemArea} >
                    <Row justify='between'>
                        <Col xs={8} md={4}>
                            <div className={styles.infoMotorista} >
                                <span className={styles.destinoViagemTitle}>Motorista</span>
                                <span className={styles.destinoViagem} title={data.motorista.nome}>{data.motorista.nome}</span> 
                            </div>
                            <div className={styles.infoMotorista} >
                                <span className={styles.destinoViagemTitle}>CPF</span>
                                <span className={styles.cpfMotorista} title={formatCpf(data.motorista.cpf)}>{' '}{formatCpf(data.motorista.cpf)}</span>
                            </div>
                        </Col>
                        <Col md={4} className={styles.dadosDestino}>
                            <span className={styles.destinoViagemTitle}>Rota do Frete</span>
                            <div>
                                <i className={"fa fa-bullseye " + styles.iconPartida} aria-hidden="true" />
                                <span className={styles.destinoViagem} title={localColeta.cidade + ' - ' + localColeta.estado}>{localColeta.cidade + ' - ' + localColeta.estado}</span>
                                <span className={styles.partidaLocal} title={localColeta.nome}>{localColeta.nome}</span>
                            </div>
                            <div>
                                <i className={"fa fa-map-marker " + styles.iconDestino} aria-hidden="true" />
                                <span className={styles.destinoViagem} title={localDescarga.cidade + ' - ' + localDescarga.estado}>{localDescarga.cidade + ' - ' + localDescarga.estado}</span>
                                <span className={styles.destinoLocal} title={localDescarga.nome}>{localDescarga.nome}</span>
                            </div>
                        </Col>
                    </Row>
                </Hidden>
                <Hidden sm lg md xl xs className={styles.destinoViagemArea}>
                    <Row justify='between'>
                        <Col xs={8} className={styles.infoMotorista}>
                            <span className={styles.destinoViagemTitle}>Motorista</span>
                            <span className={styles.destinoViagem} title={data.motorista.nome}>{data.motorista.nome}</span>
                            <span className={styles.cpfMotorista} title={formatCpf(data.motorista.cpf)}>{' '}{formatCpf(data.motorista.cpf)}</span>
                        </Col>
                        <Col xs={4} className={styles.dadosDestino}>
                            <span className={styles.destinoViagemTitle}>Rota do Frete</span>
                            <div>
                                <i className={"fa fa-bullseye " + styles.iconPartida} aria-hidden="true" />
                                <span className={styles.destinoViagem} title={localColeta.cidade + ' - ' + localColeta.estado}>{localColeta.cidade + ' - ' + localColeta.estado}</span>
                                <span className={styles.partidaLocal} title={localColeta.nome}>{localColeta.nome}</span>
                            </div>
                            <div>
                                <i className={"fa fa-map-marker " + styles.iconDestino} aria-hidden="true" />
                                <span className={styles.destinoViagem} title={localDescarga.cidade + ' - ' + localDescarga.estado}>{localDescarga.cidade + ' - ' + localDescarga.estado}</span>
                                <span className={styles.destinoLocal} title={localDescarga.nome}>{localDescarga.nome}</span>
                            </div>
                        </Col>
                    </Row>
                </Hidden>
            </div>
        )
    }
}

export default DestinoViagem
