import styled from 'styled-components';

export const Container = styled.section`
  display: grid;
  grid-template-rows: auto auto auto auto;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 40px;

  div:first-child > span {
    font-size: 17px;
    font-weight: 600;
    color: #383B37;
  }

  div:first-child > span:nth-child(2) {
    display: none;
  }

  @media (max-width: 414px) {
    div:first-child > span:first-child {
      display: none;
      font-size: 22px;
    }

    div:first-child > span:nth-child(2) {
      display: block;
    }
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  div {
    display: flex;
    justify-content: flex-end;

    img {
      width: 120px;
    }
  }

  span {
    font-size: 9px;
    margin-top: 3px;
    font-weight: bold;
  }

  @media (max-width: 414px) {
    span {
      margin-top: 5px;
      font-size: 14px;
    }
  }
`;

export const LotContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: -10px;

  @media (max-width: 414px) {
    flex-direction: column;
  }
`;

export const CONumberValidityContainer = styled.div`
  @media (max-width: 414px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 22px;
  }
`;

export const COImageNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 70px;
  height: 63px;
  background: #6CA2392F;
  border-radius: 8px;

  @media (max-width: 414px) {
    flex-direction: row;
    justify-content: flex-start;

    width: 240px;
    height: 50px;
    padding-left: 10px;
  }
`;

export const TenantLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 61px;
  height: 36px;
  margin-top: 5px;
  margin-bottom: 2px;
  background: #ffffff;
  border-radius: 8px;

  img {
    width: 27px;
    height: 24px;
  }

  @media (max-width: 414px) {
    width: 56px;
    height: 39px;
  }
`;

export const CONumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 10px;
    font-weight: 300;
    line-height: 1.2;
  }

  & > span:nth-child(2) {
    font-size: 10px;
    font-weight: 600;
  }

  @media (max-width: 414px) {
    align-items: flex-start;
    justify-content: flex-start;

    span {
      font-size: 12px;
    }

    margin-left: 19px;
  }
`;

export const ValidityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 70px;
  height: 30px;
  background: #595758;
  color: #ffffff;
  margin-top: 5px;
  font-size: 10px;
  border-radius: 8px;

  & > span:first-child {
    font-weight: 300;
  }

  span {
    font-weight: 600;
    line-height: 1.4;
  }

  @media (max-width: 414px) {
    margin-top: 0;
    margin-left: 0;
    width: 107px;
    height: 50px;

    span {
      font-size: 12px;
    }
  }
`;

export const COInfoResponsibleCompanyContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-left: 15px;

  @media (max-width: 414px) {
    margin-top: 10px;
    margin-left: 0;
  }
`;

export const COInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div > span {
    color: #53772C;
    font-weight: 600;
    font-size: 10px;
  }

  @media (max-width: 414px) {
    & > div > span {
      font-size: 12px;
    }
  }
`;

export const COInfo = styled.div`
  display: grid;
  grid-template-columns: 45% 52%;
  gap: 1rem;

  font-size: 10px;
  line-height: 1.4;

  @media (max-width: 414px) {
    display: grid;
    grid-template-columns: 51% 49%;

    font-size: 12px;
  }
`;

export const COInfoContractCodeContainer = styled.div`
  display: grid;
  grid-template-columns: 46% 47%;
  gap: 1rem;

  & > div {
    display: flex;
    flex-direction: column;

    padding-right: 10px;

    span {
      font-weight: 600;
    }

    span:first-child {
      font-weight: 300;
    }

    span:last-child {
      word-break: break-word;
    }
  }

  @media (max-width: 414px) {
    grid-template-columns: none;
    grid-template-rows: 50% 50%;
    gap: 0;

    & > div + div {
      margin-top: 5px;
    }
  }
`;

export const COInfoProductVolumeContainer = styled.div`
  display: grid;
  grid-template-columns: 39% 54%;
  gap: 1.15rem;

  & > div {
    display: flex;
    flex-direction: column;

    padding-right: 10px;

    span {
      font-weight: 600;
    }

    span:first-child {
      font-weight: 300;
    }

    span:last-child {
      word-break: break-word;
    }
  }

  @media (max-width: 414px) {
    grid-template-columns: none;
    grid-template-rows: 50% 50%;
    gap: 0;

    & > div + div {
      margin-top: 5px;
    }
  }
`;

export const ResponsibleCompanyContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div > span {
    color: #53772C;
    font-size: 10px;
    font-weight: 600;
    line-height: 1.4;
  }

  @media (max-width: 414px) {
    margin-top: 10px;

    & > div > span {
      font-size: 12px;
    }
  }
`;

export const ResponsibleCompanyInfo = styled.div`
  display: grid;
  grid-template-columns: 45% 52%;
  gap: 1rem;

  margin-top: 2px;
  font-size: 10px;
  line-height: 1.4;

  div {
    display: flex;
    flex-direction: column;

    width: 204px;

    & > span:first-child {
      font-weight: 300;
    }

    span {
      font-weight: 600;
    }
  }

  @media (max-width: 414px) {
    grid-template-columns: 51% 49%;

    font-size: 12px;

    div {
      width: 100%;
      padding-right: 15px;
    }
  }
`;
