import React, { useEffect, useRef, useState } from 'react';

import {
  Container,
  ItineraryContainer,
  ItineraryTimeLineContainer,
  DriverContainer,
  DriverInfoContainer,
} from './styles';

const Itinerary = ({ data = {} }) => {
  const [timelineHeight, setTimelineHeight] = useState('');
  const dischargeLocation = useRef(null);

  let itineraryInfo = data || {};

  useEffect(() => {
    setTimelineHeight(
      dischargeLocation.current.offsetHeight
    );
  }, [data]);

  return (
    <Container>
      <ItineraryContainer>
        <div>
          <span>Roteiro</span>
        </div>

        <ItineraryTimeLineContainer timelineHeight={timelineHeight}>
          <div ref={dischargeLocation} className="discharge-location">
            <span>Local de carregamento</span>
            <span>{itineraryInfo.collectionLocationName}</span>
            <span>
              {
                `${itineraryInfo.collectionLocationPublicPlace},
                  ${itineraryInfo.collectionLocationNumber} -
                  ${itineraryInfo.collectionLocationNeighborhood} -
                  ${itineraryInfo.collectionLocationCity} -
                  ${itineraryInfo.collectionLocationState}`
              }
            </span>
            <span>CEP: {itineraryInfo.collectionLocationZipCode}</span>
          </div>

          <div>
            <span>Local de entrega</span>
            <span>{itineraryInfo.dischargeLocationName}</span>
            <span>
              {
                `${itineraryInfo.dischargeLocationPublicPlace},
                  ${itineraryInfo.dischargeLocationNumber} -
                  ${itineraryInfo.dischargeLocationNeighborhood} -
                  ${itineraryInfo.dischargeLocationCity} -
                  ${itineraryInfo.dischargeLocationState}`
              }
            </span>
            <span>CEP: {itineraryInfo.dischargeLocationZipCode}</span>
          </div>
        </ItineraryTimeLineContainer>
      </ItineraryContainer>

      <DriverContainer>
        <div>
          <span>Dados do motorista</span>
        </div>

        <DriverInfoContainer>
          <div>
            <span>Nome</span>
            <span>{itineraryInfo.driverName}</span>
          </div>

          <div>
            <span>CPF</span>
            <span>{itineraryInfo.driverCpf}</span>
          </div>

          <div>
            <span>Telefone</span>
            <span>{itineraryInfo.driverCelPhone}</span>
          </div>
        </DriverInfoContainer>
      </DriverContainer>
    </Container>
  );
}

export default Itinerary;
