import React from 'react'
import styles from './CodigoViagem.module.scss';
import { Row, Col } from 'react-grid-system';
import { formatNumber } from '../../../shared/Format'
import moment from 'moment'

class CodigoViagem extends React.PureComponent {
    render() {
        const {data} = this.props;
        return (
            <div>
                <Row justify='around' className={styles.codigoViagemArea}>
                    <Col md={2} xs={6} >
                        <span className={styles.codigoViagemTitle}>Código da viagem</span>
                        <span className={styles.codigoViagem}>#{data.viagem.codigo}</span>
                    </Col>
                    <Col md={2} xs={6}>
                        <span className={styles.codigoViagemTitle}>Criação da viagem</span>
                        <span className={styles.codigoViagem}>{moment(data.viagem.dataCriacao).format('DD/MM/YYYY')}</span>
                    </Col>
                    <Col md={2} xs={6} className={styles.codigoViagemMobile}>
                        <span className={styles.codigoViagemTitle}>Produto</span>
                        <span className={styles.codigoViagem} title={data.informacaoLogistica.produto.nome}>{data.informacaoLogistica.produto.nome}</span>
                    </Col>
                    <Col md={2} xs={6} className={styles.codigoViagemMobile}>
                        <span className={styles.codigoViagemTitle}>Peso Estimado</span>
                        <span className={styles.codigoViagem}>{formatNumber(data.informacaoLogistica.produto.pesoEstimado.quantidade)} {data.informacaoLogistica.produto.pesoEstimado.unidadeMedida}</span>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default CodigoViagem