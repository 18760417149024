import React, { PureComponent } from 'react'
import { Row, Col } from 'react-grid-system';
import styles from './DadosTransportadora.module.scss';

class DadosTransportadora extends PureComponent{
    render(){
        const dados = this.props.data && this.props.data.informacaoLogistica;
        let urlLogo = dados.urlLogoEmbarcadora ? dados.urlLogoEmbarcadora : dados.urlLogoTransportadora;
        let temEmbarcador = dados.embarcadora ? true : false;
        
        return(
            <div>
                <Row md={12}>
                    <Col md={3} offset={{ md: 1}} xs={2}>
                        <div className={styles.wrapperImg}>
                            <img alt='' src={urlLogo} className={styles.resultImg} />
                        </div>
                    </Col>
                    <Col md={3} offset={{ xs: 3, md: 1 }} xs={3} className={styles.resultTranspData}>
                        <div style={{marginTop: '10px'}} className={temEmbarcador ?  undefined : styles.hidden}>
                            <span className={styles.resultTranspTitle}>Embarcadora</span>
                            <span className={styles.resultTranspName} title={dados.embarcadora}>{dados.embarcadora}</span>
                        </div>
                        <div style={temEmbarcador ? { marginTop: '20px' } : { marginTop: '35px' }}>
                            <span className={styles.resultTranspTitle}>Transportadora</span>
                            <span className={styles.resultTranspName} title={dados.transportadora}>{dados.transportadora}</span>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default DadosTransportadora